<template>
     <div>
          <v-menu
               v-model="dPicker"
               :close-on-content-click="false"
               offset-y
               min-width="290"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="localModel"
                :label="label"
                :placeholder="placeholder"
                :hint="hint"
                readonly
                :error-messages="errors"
                v-bind="attrs"
                v-on="on"
                :messages="inputMessage"
            ></v-text-field>
        </template>
          <v-time-picker
               @input="dPicker = false"
               v-model="localModel"
               format="24hr"
          ></v-time-picker>
          </v-menu>
     </div>
</template>
<script>
export default {
     name: "timepicker",
     props:{
          time:{
               type: String,
               required: false
          },
          label: {
               type: String,
               required: true
          },
          placeholder: {
               type: String,
               required: false,
               default: "SELECT A time"
          },
          inputMessage:{
               type: String,
               default: "",
          },
          errors:{
               type: Array,
               required: false,
          },
          hint:{
               type: String,
               default: 'select a time'
          }
     },
     computed:{
          localModel: {
                get() {
                    return this.time
                },
                set(value) {
                    this.$emit('update:time', value)
                }
          },
     },
     data () {
          return {
            dPicker: false
          }
     },
}
</script>